import { useCallback, useEffect } from 'react';
import { analyticsConfig } from 'config/analytics';

declare global {
  interface Window {
    gtag?: any;
    dataLayer?: any[];
  }
}

export const useAnalytics = (): void => {
  const incluirGoogleAnalytics = useCallback(() => {
    if (process.env.REACT_APP_NODE_ENV !== 'production') return;

    const analyticsId = analyticsConfig.googleAnalyticsId;

    if (!analyticsId) return;

    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${analyticsId}`;
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag(...args: any[]) {
        window.dataLayer?.push(args);
      }
      gtag('js', new Date());
      gtag('config', analyticsId);
    };
  }, []);

  useEffect(() => {
    incluirGoogleAnalytics();
  }, [incluirGoogleAnalytics]);
};
